<app-toll-gate-navbar *ngIf="userType == 'ADMIN'"></app-toll-gate-navbar>
<div class="container-fluid">
  <form [formGroup]="gateForm">
    <div *ngIf="userType == 'ADMIN'" class="row"></div>
    <div class="row mt-4">
      <div class="col-6 mt-5 text-end">
        <div>
          <label>{{"Service Type" | translate}} :</label>
          <input class="ml-3" type="radio" role="button" value="Warehouse" formControlName="serviceType"
            (change)="selectServiceType($event)">
          <label class="ml-1">{{"Warehouse" | translate}}</label>
          <input class="ml-3" type="radio" role="button" value="Depot" formControlName="serviceType"
            (change)="selectServiceType($event)">
          <label class="ml-1">{{"Depot" | translate}}</label>
        </div>
        <span *ngIf="formErrorValidation.serviceType" class="text-danger">{{ formErrorValidation.serviceType | translate
          }}</span>
      </div>
      <div class="col-6 mt-5 text-center">
        <div>
          <label>Language :</label>
          <input class="ml-3" type="radio" role="button" checked name="language" (change)="switchLanguage('en')">
          <label class="ml-1">English</label>
          <input class="ml-3" type="radio" role="button" name="language" (change)="switchLanguage('ta')">
          <label class="ml-1">தமிழ்</label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div *ngIf="showFields >= 1" class="col-md-3 col-lg-3 col-12 mt-2">
        <ng-multiselect-dropdown class="" [placeholder]="'Select an option'" [settings]="dropdownSettings"
          [data]="dropdownList" [(ngModel)]="selectedItem" (onSelect)="selectMember($event)"
          formControlName="memberName">
        </ng-multiselect-dropdown>
        <span *ngIf="formErrorValidation.memberName" class="text-danger">{{ formErrorValidation.memberName }}</span>
      </div>

      <div *ngIf="showFields >= 2" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating ">
          <select class="form-select" id="vehType" formControlName="vehType"
            (change)="selectVehicleType($event.target.value)">
            <option value="" disabled selected hidden>
              {{"Select Vehicle Type" | translate}}
            </option>
            <option *ngFor="let veh of vehicleOption" value="{{veh}}">{{veh | translate}}</option>
          </select>
          <label class="form-label">{{"Vehicle Type" | translate}}<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.vehType" class="text-danger">{{ formErrorValidation.vehType | translate
            }}</span>
        </div>
      </div>

      <div *ngIf="showFields >= 3" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="row">
          <div class="col-6 p-0 m-0">
            <div class="form-floating mb-3">
              <input type="tel" class="form-control" placeholder="Vehicle Type Seq" formControlName="vehicleTypeSeq"
                readonly>
              <label> <span class="text-danger"></span></label>
            </div>
          </div>
          <div class="col-6 p-0 m-0">
            <div class="form-floating">
              <input type="tel" class="form-control" placeholder="Enter Irm No" maxlength="4" formControlName="irmNo"
                (keypress)="OnlyNumbers($event)" (input)="onCheckIrmNo($event)">
              <label>{{"Enter Irm No" | translate}}<span class="text-danger">*</span></label>
            </div>
          </div>
          <span *ngIf="formErrorValidation.irmNo && !irmIsAlreadyExist" class="text-danger">{{ formErrorValidation.irmNo
            | translate}}</span>
          <span *ngIf="irmIsAlreadyExist" class="text-danger">{{irmNoError | translate}}</span>
        </div>
      </div>

      <div *ngIf="showFields >= 3" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Vehicle Entry Date Time"
            formControlName="vehEntryDateTime" [max]="futureDateDisable">
          <label class="form-label">{{"Vehicle Entry Date Time" | translate}}<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.vehEntryTime" class="text-danger">{{formErrorValidation.vehEntryTime |
            translate}}</span>
        </div>
      </div>

      <div *ngIf="showFields >= 3" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" id="wheelCount" formControlName="wheelCount"
            (change)="wheelCountFunction($event)">
            <option value="" disabled selected hidden>
              {{"Select Wheel Count" | translate}}
            </option>
            <option *ngFor="let wheel of wheelOptions" value="{{wheel}}">{{wheel}}</option>
          </select>
          <label class="form-label">{{"Wheel Count" | translate}}<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.wheelCount" class="text-danger">{{ formErrorValidation.wheelCount | translate
            }}</span>
        </div>
      </div>

      <div *ngIf="heavy && showFields >= 4" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" id="purpose" formControlName="purpose" (change)="chargeCal($event)">
            <!-- <option value="" disabled selected hidden>
              {{"Select Type" | translate}}
            </option> -->
            <option value="Loading">{{"Empty" | translate}}</option>
            <option value="Unloading">{{"With Goods" | translate}}</option>
          </select>
          <label class="form-label">{{"Select Type" | translate}}<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.purpose" class="text-danger">{{ formErrorValidation.purpose | translate
            }}</span>
        </div>
      </div>

      <div *ngIf="heavy && showFields >= 5" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" id="vehState" formControlName="vehState" (change)="changeState($event)">
            <option value="" disabled selected hidden>
              {{"Vehicle State" | translate}}
            </option>
            <!--<option *ngFor="let state of allStates" value="{{state}}">{{state}}</option>-->
            <option value="Tamil Nadu">{{"Tamil Nadu" | translate}}</option>
            <option value="Other State">{{"Other State" | translate}}</option>
          </select>
          <label class="form-label">{{"Vehicle State" | translate }}</label>
        </div>
      </div>

      <div *ngIf="heavy && showFields >= 6 && loadType" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Weight MT" formControlName="weightMt"
            (input)="calculateCharges($event)">
          <label class="form-label">{{"Weight MT" | translate}}<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.weightMt" class="text-danger">{{ formErrorValidation.weightMt |
            translate}}</span>
        </div>
      </div>

      <div *ngIf="heavy && showFields >= 7 && loadType" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select" formControlName="commodityName">
            <option value="" disabled selected hidden>
              Select Commodity Name
            </option>
            <option *ngFor="let com of commodityNames" value="{{com}}">{{com}}</option>
          </select>
          <label class="form-label">{{"Commodity Name" | translate}}<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.commodityName" class="text-danger">{{ formErrorValidation.commodityName
            }}</span>
        </div>
      </div>

      <div *ngIf="showFields >= 8" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="eg: TN-00-AA-0000"
            formControlName="vehNumberPlate">
          <label class="form-label">{{"Vehicle Number" | translate}}<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.vehNumberPlate" class="text-danger">{{ formErrorValidation.vehNumberPlate |
            translate}}</span>
        </div>
      </div>
      <div *ngIf="showFields >= 8" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Driver Name"
            formControlName="driverName">
          <label class="form-label">{{"Driver Name" | translate}}</label>
        </div>
      </div>
      <div *ngIf="showFields >= 8" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Driver Phone No"
            formControlName="driverPhNo" (keypress)="OnlyNumbers($event)" maxlength="10">
          <label class="form-label">{{"Driver Phone No" | translate}}</label>
        </div>
      </div>
      <div *ngIf="showFields >= 8" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select" formControlName="modeOfPayment"
            (change)="onSelectPaymentType($event)">
            <option value="Cash on Hand">Cash on Hand</option>
            <option value="Bank">Bank</option>
          </select>
          <label class="form-label">{{"Mode of Payment" | translate}}</label>
        </div>
      </div>
      <div *ngIf="showRefNumber" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="UTR or Ref Number"
            formControlName="refNumber">
          <label class="form-label">{{"UTR or Ref Number" | translate}} <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="formErrorValidation.refNumber" class="text-danger">{{ formErrorValidation.refNumber |
          translate}}</span>
      </div>
      <div *ngIf="showFields >= 5" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Vehicle Entry Date Time"
            formControlName="vehEntryDateTime" [max]="futureDateDisable">
          <label class="form-label">{{"Vehicle Entry Date Time" | translate}}<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.vehEntryTime" class="text-danger">{{formErrorValidation.vehEntryTime |
            translate}}</span>
        </div>
      </div>
      <div *ngIf="showFields >= 5" class="col-md-3 col-lg-3 col-12 mt-2">
        <div class="form-floating">
          <input type="text" disabled class="form-control" placeholder="Charges" formControlName="charges">
          <label class="form-label">{{"Charges" | translate}}</label>
        </div>
      </div>
    </div>
    <div *ngIf="showFields >= 8" class="row justify-content-center mt-4 mb-5">
      <button type="submit" class="btn btn-success col-md-1" (click)="formValidation(gateForm)">{{"Save" |
        translate}}</button>
    </div>
  </form>
</div>

<div class="modal " role="option" [style.display]="serviceType ? 'block' : 'none'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="model-tittle">Storage & Service Type</h4>
        <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-x-circle-fill mt-2" viewBox="0 0 16 16">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>
      </div>
      <div class="modal-body">
        <form [formGroup]="gateForm">
          <div *ngIf="storageField" class="form-floating">
            <select class="form-select" id="storageType" formControlName="storageType">
              <option value="" disabled selected hidden>
                Select Storage Type
              </option>
              <option value="Common Warehouse">Common Warehouse</option>
              <option value="SLCM">SLCM</option>
              <option value="Cold Storage">Cold Storage</option>
              <option value="Pre Processing">Pre Processing</option>
              <option value="Packing">Packing</option>
              <option value="Solar Tunnel Dryer">Solar Tunnel Dryer</option>
            </select>
            <label class="form-label">Select Storage Type<span style="color:red;">*</span></label>
          </div>
          <div *ngIf="serviceField" class="text-center row col-12 justify-content-center">
            <div class="col-5 justify-content-center">
              <input type="radio" role="button" value="Deposit" formControlName="serviceType">
              <label class="ml-1">Deposit</label>
            </div>
            <div class="col-5 justify-content-center">
              <input class="ml-3" type="radio" role="button" value="Delivery" formControlName="serviceType">
              <label class="ml-1">Delivery</label>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button *ngIf="storageField" type="button" class="btn btn-success" style="background-color: #22c158"
          data-dismiss="modal" (click)="storageClose()"><b>Ok</b>
        </button>
        <button *ngIf="serviceField" type="button" class="btn btn-success" style="background-color: #22c158"
          data-dismiss="modal" (click)="serviceClose()"><b>Ok</b>
        </button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>